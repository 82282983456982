<template>
  <div
    v-dompurify-html="formatImgSrc(content)"
    @click="onClickHtml"
    class="rich-text-content"
  ></div>
</template>
<script>
import { formatImgSrc, onClickHtml } from '@/utils/utils'

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return {
      formatImgSrc,
      onClickHtml,
    }
  },
}
</script>
<style>
.rich-text-content img {
  max-width: 100%;
  height: auto;
}
.rich-text-content video {
  max-width: 100%;
  height: auto;
}
.rich-text-content a {
  color: #fa5151;
}
</style>
