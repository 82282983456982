import {createAPI} from './create-api'
import auth from './module/auth'
import store from '@/store'
import router from "@/router";
import {hideLoading, showLoading} from "../utils/utils";
import {showFailToast} from 'vant'
import i18n from "@/lang";


let refreshTokenPromise;

const userApiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const api = createAPI({baseURL: userApiBaseUrl + "/web"});
api.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    if (store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
    }

    // 获取本地缓存的语言
    const locale = i18n.global.locale.value
    config.headers['Accept-Language'] = locale
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT;
    }
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

//响应拦截器
let goLoginFlagTimer = null;
api.interceptors.response.use(
  (response) => {
    console.log(router.currentRoute.value.name)
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    const silent = config.silent;
    let routeId = router.currentRoute.value.params.id || null;
    // 如果不存在id，就从缓存中取
    if (!routeId) {
      routeId = localStorage.getItem('merchantId') || null
    }
    const isAzureApi = config.isAzureApi
    if (!noLoading) {
      hideLoading();
    }
    if (!silent) {
      if (isAzureApi) {
        // azure相关接口
      } else {
        switch (status) {
          case 400:
            const errorList = error?.response?.data;
            if (typeof errorList === "object") {
              errorList.forEach((errorMessage) => {
                showFailToast(errorMessage)
              });
            }
            break;
          case 401:
            const config = error.config || {};
            if (config._retry) {
              return Promise.reject(error);
            }
            if (!refreshTokenPromise) {
              config._retry = true;
              refreshTokenPromise = authApi.anonymousLogin().then((res) => {
                refreshTokenPromise = null;
                return res
              });
            }

            return refreshTokenPromise.then((res) => {
              const token = res.data.token;
              store.dispatch("setToken", token);
              // 更新请求头中的 Token
              if (config && config.headers) {
                config.headers["Authorization"] = `Bearer ${token}`;
              }
              return api.request(config);
            }).catch((error) => {
              console.error(error);
              // logout();
              return Promise.reject(error);
            });
            break;
          default:
            break;
        }
      }
    }

    return Promise.reject(error);
  }
);

export const authApi = auth(api)
