export const m = {
  // 日语语言包
  app: {
    login: 'ログイン',
    'Home': 'ホーム',
    'Nearby': '周辺',
    'Message': 'メッセージ',
    'Mypage': 'マイページ',
  },
  common: {
    // 暂无数据
    'NoData': 'データがありません',
    // 敬请期待
    'ComingSoon': 'お楽しみに',
    // 请重新登录
    'PleaseLoginAgain': '再度ログインしてください',
    // 登录已过期，请重新登录
    'LoginExpiredPleaseLoginAgain': 'ログインが期限切れになりました。再度ログインしてください',
    // 请在小程序中打开
    'PleaseOpenInMiniProgram': 'miniプログラムで開いてください',
    // 复制成功
    'copySuccess': 'コピー成功',
    // 复制失败
    'copyFail': 'コピーに失敗しました',
  },
  home: {
    'readMore': 'もっと読む',
    'hotelEventOverview': 'ホテルイベント概要',
    // 暂无公告
    'NoAnnouncement': 'お知らせはありません',
    // 取消
    'Cancel': 'キャンセル',
  },
  accountHome: {
    welcome: 'ようこそ 🎉',
    Login: 'ログイン',
    Register: '登録',
    ContinueAsAGuest: 'ゲストとして続行',
  },
  login: {
    'WelcomeBackGladToSeeYouAgain': 'お帰りなさい！ またお会いできてうれしいです！',
    // Enter your email
    'EnterYourEmail': 'メールアドレスを入力してください',
    // Enter your password
    'EnterYourPassword': 'パスワードを入力してください',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '正しいメールアドレスを入力してください',
    // Forgot Password?
    'ForgotPassword': 'パスワードをお忘れですか？',
    // Don’t have an account?
    'DontHaveAnAccount': 'アカウントをお持ちでないですか？',
    // Register Now
    'RegisterNow': '今すぐ登録',
    // Login
    'Login': 'ログイン',
  },
  register: {
    'WelcomeBackGladToSeeYouAgain': 'お帰りなさい！ またお会いできてうれしいです！',
    // Nickname
    'Nickname': 'ニックネーム',
    // Email
    'Email': 'メールアドレスを入力してください',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '正しいメールアドレスを入力してください',
    // Password
    'Password': 'パスワードを入力してください',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': 'パスワードが一致しません',
    // Confirm password
    'ConfirmPassword': 'パスワードを認証する',
    // Agree and Register
    'AgreeAndRegister': '登録する',
    // 发送验证码
    'SendCode': 'コードを送信する',
    // 验证
    'Verify': '確認',
    // 注册用户
    'RegisteredUsers': '新規ユーザーとして登録する',
    // OTP Verification
    'OTPVerification': 'OTP検証',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': 'メールアドレスに送信した確認コードを入力してください。',
    // 请填写密码
    'PleaseEnterYourPassword': 'パスワードを入力してください',
    // 将会作为您的登录密码
    'WillBeUsedAsYourLoginPassword': 'ログインパスワードとして使用されます',
    // RegisteredSuccessfully
    'RegisteredSuccessfully': '登録に成功しました',
  },
  sso: {
    // Or Login with
    'OrLoginWith': 'またはログイン',
  },
  forgotPassword: {
    // Forgot Password
    'ForgotPassword': 'パスワードをお忘れですか？',
    // Don't worry! It occurs. Please enter the email address linked with your account.
    'DontWorryItOccursPleaseEnterTheEmailAddressLinkedWithYourAccount': '心配しないでください！ メールアドレスを入力してください。',
    // Enter your email
    'EnterYourEmail': 'メールアドレスを入力してください',
    // Send Code
    'SendCode': 'コードを送信',
    // Remember Password?
    'RememberPassword': 'パスワードをお忘れですか？',
    // Login
    'Login': 'ログイン',
    // OTP Verification
    'OTPVerification': 'OTP検証',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': 'メールアドレスに送信した確認コードを入力してください。',
    // Verify
    'Verify': '確認',
    // Didn’t received code?
    'DidntReceivedCode': 'コードを受け取りませんでしたか？',
    // Resend
    'Resend': '再送信',
    // Create new password
    'CreateNewPassword': '新しいパスワードを作成する',
    // Your new password must be unique from those previously used.
    'YourNewPasswordMustBeUniqueFromThosePreviouslyUsed': '以前に使用したパスワードとは異なるパスワードを入力してください。',
    // New Password
    'NewPassword': '新しいパスワード',
    // Confirm Password
    'ConfirmPassword': 'パスワードを認証する',
    // Reset Password
    'ResetPassword': 'パスワードを再設定する',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '正しいメールアドレスを入力してください',
    // 请输入密码
    'PleaseEnterYourPassword': 'パスワードを入力してください',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': 'パスワードが一致しません',
    // 发送成功
    'SendSuccessfully': '送信成功',
  },
  passwordChanged: {
    // Password Changed!
    'PasswordChanged': 'パスワードが変更されました！',
    // Your password has been changed successfully.
    'YourPasswordHasBeenChangedSuccessfully': 'パスワードが正常に変更されました。',
    // Back to Login
    'BackToLogin': 'ログインに戻る',
  },
  FeedBack: {
    // Name
    'Name': '名前',
    // Please enter the English name on the passport!
    'PleaseEnterTheEnglishNameOnThePassport': 'パスポートの英語名を入力してください！',
    // Telphone
    'Telphone': '電話番号',
    // lease enter your phone number
    'LeaseEnterYourPhoneNumber': '電話番号を入力してください',
    // Email
    'Email': 'メールアドレス',
    // Please enter your e-mail address
    'PleaseEnterYourEMailAddress': 'メールアドレスを入力してください',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '正しいメールアドレスを入力してください',
    // Check-in
    'CheckIn': 'チェックイン日',
    // Please select a check-in date
    'PleaseSelectACheckInDate': 'チェックイン日を選択してください',
    // Feedback content
    'FeedbackContent': 'フィードバック内容',
    // 请输入反馈内容
    'PleaseEnterYourFeedbackContent': 'フィードバック内容を入力してください',
    // 提交成功
    'SubmittedSuccessfully': '提出成功',
    // Please enter your comments and suggestions, and you can also contact the hotel through here if the item is lost.
    'PleaseEnterYourCommentsAndSuggestionsAndYouCanAlsoContactTheHotelThroughHereIfTheItemIsLost': 'コメントと提案を入力してください。アイテムが紛失した場合は、ここからホテルに連絡することもできます。',
    // Sent
    'Sent': '送信',
  },
  navigation: {
    // Save to album
    'SaveToAlbum': '保存',
    // Navigate to the hotel
    'NavigateToTheHotel': 'ホテルへのナビゲート',
    // ※可长按保存
    'LongPressToSave': '※長押しして保存',
  },
  CouponFormat: {
    // See details
    'SeeDetails': '詳細を見る',
    // Get
    'Get': '取得する',
    // Use
    'Use': '使用する',
    // 永久有效
    'PermanentValidity': '永久有効',
    // 有效期至：${item.endTimeDisplay}
    'ValidUntil': '有効期限：{endTimeDisplay}',
    // 有效天数：${item.effeDays}天
    'ValidDays': '有効期間：{effeDays}日',
    // 结束时间：${item.endTimeDisplay}
    'EndTime': '終了時間：{endTimeDisplay}',
    // 使用时间：${item.useTimeDisplay}
    'UseTime': '使用時間：{useTimeDisplay}',
    // `每人限领：${item.limit}次`
    'Limit': 'お一人様あたりの制限数：{limit}回',
  },
  shopList: {
    // JPY
    'JPY': 'JPY',
    // RMB
    'RMB': 'RMB',
  },
  barCart: {
    // JPY
    'JPY': 'JPY',
    // Next
    'Next': '次へ',
  },
  userCenter: {
    // Coupon
    'Coupon': 'クーポン',
    // Membership
    'Membership': 'ホテル会員',
    // Collection
    'Collection': 'コレクション',
    // My Order
    'MyOrder': '注文',
    // Coupon Center
    'CouponCenter': 'クーポンセンター',
    // Settings
    'Settings': '設定',
    // Service
    'Service': 'サービス',
    // Product review
    'ProductReview': '商品レビュー',
    // About QR SKI
    'AboutQRMALL': 'QR SKIについて',
    // Logout
    'Logout': 'ログアウト',
    // 我的收藏
    'MyCollection': 'マイコレクション',
    // 我的优惠券
    'MyCoupon': 'マイクーポン',
  },
  eventList: {
    // 查看详情
    'SeeDetails': '詳細見る',
  },
  collectionList: {
    // 访问足迹
    'AccessFootprint': 'アクセス履歴',
    // 我的收藏
    'MyCollection': 'マイコレクション',
    // 点击查看〉
    'ClickToView': '詳細を見る〉',
    // 确认
    'Confirm': '確認',
    // 确定要跳转吗？
    'ConfirmToJump': '確定してジャンプしますか？',
  },
  myCouponList: {
    // 可使用
    'Available': '利用可能',
    // 已过期
    'Expired': '期限切れ',
    // 已使用
    'Used': '使用済み',
    // 没有更多啦~
    'NoMore': 'これ以上ない',
    // 即将过期
    'Expiring': '期限切れ',
    // 去使用
    'GoToUse': '使用する',
    // 领取
    'Get': '受け取る',
  },
  NotFound: {
    // 雪场信息获取失败
    'HotelInformationFailedToGet': '情報の取得に失敗しました',
    // 查看介绍
    'ViewIntroduction': '紹介を見る',
  },
  Article: {
    // 营业时间
    'BusinessHours': '営業時間',
    // 介绍
    'Introduction': '紹介',
  },
  DiscoverDetail: {
    // 问路卡
    'AskForTheWayCard': '道を尋ねる',
    // 导航
    'Navigation': 'ナビ',
    // 简介
    'Introduction': '紹介',
    // 基本信息
    'BasicInformation': '基本情報',
    // 地址
    'Address': '住所',
    // 营业时间
    'BusinessHours': '営業時間',
    // 本页信息由第三方来源提供，不保证信息及时有效
    'FooterTip': 'このページの情報は第三者から提供されており、情報の有効性を保証するものではありません',
    // （人均消费）
    'AverageConsumption': '（一人当たりの消費）',
  },
  Coupon: {
    // 商家专属
    'MerchantExclusive': '商家専用',
    // 平台通用
    'PlatformGeneric': 'プラットフォーム',
    // 10%免税
    '10DutyFree': '10%免税',
    // 特定店铺
    'SpecificStore': '特定店舗',
    // 去使用
    'GoToUse': '使用する',
    // 去领取
    'GoToGet': '受け取る',
    // 去查看
    'GoToView': '詳細',
  },
  CouponDetail: {
    // 10%免税
    '10DutyFree': '10%免税',
    // 优惠券详情
    'CouponDetail': 'クーポンの詳細',
    // 特定店铺
    'SpecificStore': '特定店舗',
    // 使用方法
    'UseMethod': '使用方法',
    // 使用须知
    'UseNotice': '使用须知',
    // 本优惠券为第三方搜集，进店时请与店员做好沟通，本程序不承担连带责任
    'Disclaimer': 'このクーポンは第三者によって収集され、店員とのコミュニケーションを確認してください。このプログラムは連帯責任を負いません',
    // 已过期
    'Expired': '期限切れ',
    // 已使用
    'Used': '使用済み',
    // 请让雪场服务人员点击，自己点击无效，敬请谅解
    'PleaseLetTheHotelStaffClickItYourself': 'ホテルのスタッフにクリックさせてください。自分でクリックしても無効です。ご了承ください',
    // 由服务人员使用
    'UsedByTheServiceStaff': 'サービススタッフが使用',
    // 领取
    'Get': '受け取る',
    // 确认
    'Confirm': '確認',
    // 确认要使用此优惠券吗？
    'ConfirmToUseThisCoupon': 'このクーポンを使用しますか？',
    // 请登录
    'PleaseLogin': 'ログインしてください',
  },
  Cropper: {
    // 裁切
    'Crop': '切り取り',
  },
  UserSetting: {
    // 请输入昵称
    'PleaseEnterYourNickname': 'ニックネームを入力してください',
    // 手机号
    'PhoneNumber': '電話番号',
    // 请输入您的手机号
    'PleaseEnterYourPhoneNumber': '電話番号を入力してください',
    // 保存
    'Save': '保存',
  },
  AiChat: {
    // 觉得答案如何？
    'HowDoYouFeelAboutTheAnswer': '答えはどうですか？',
    // 感谢您的回馈！
    'ThankYouForYourFeedback': 'フィードバックありがとうございます！',
    // 按住 说话
    'PressAndSpeak': '押して話す',
    // 发送
    'Send': '送信',
    // 松开 结束
    'ReleaseEnd': '離して終了',
    // 最多支持${queryLimit.value}个字的发送
    'MaximumSupportForSendingWords': '最大{queryLimit}文字の送信をサポート',
    // 连接发生错误
    'ConnectionError': '接続エラー',
    // 你好，我是${res.data.name}的小助手
    'HelloIAmTheAssistant': 'こんにちは、私は{name}のアシスタントです',
    // ${merchatData.name}的AI小助手为您服务
    'AIAssistantForMerchat': '{name}のAIアシスタントがサービスを提供します',
    // 无法查询到设施信息，请重新扫码
    'UnableToQueryFacilityInformationPleaseScanAgain': '施設情報を検索できません。もう一度スキャンしてください',
    // 该设施已暂停咨询服务，请重新扫码
    'TheFacilityHasSuspendedConsultingServicesPleaseScanAgain': 'この施設は一時的に相談サービスを停止しました。もう一度スキャンしてください',
    // 查询设施信息失败，请稍后再试
    'FailedToQueryFacilityInformationPleaseTryAgainLater': '施設情報の検索に失敗しました。後でもう一度お試しください',
    // 以下是一些常见问题，请点击您想问的问题，确认答案。
    'TheFollowingAreSomeCommonQuestionsPleaseClickOnTheQuestionYouWantToAskToConfirmTheAnswer': '以下は一般的な質問です。質問したい質問をクリックして、答えを確認してください。',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '音声認識の初期化に失敗しました。マイクの権限を確認してください',
    // 很抱歉，可以更详细的描述您的问题吗？
    'SorryCanYouDescribeYourProblemInMoreDetail': '申し訳ありませんが、問題を詳細に説明できますか？',
    // 开启新的对话
    'StartNewConversation': '新しい会話を開始',
    // 今天
    'Today': '今日',
    // 最近7天
    'Last7Days': '過去7日間',
    // 最近30天
    'MoreThan7Days': '7日前',
    // 30天前
    'MoreThan30Days': '30日前',
  },
  TranslationHelper: {
    // 说日语
    'SayLang': '日本語',
    // 说日语
    'SayJp': '日本語',
    // 按住 说话
    'PressAndSpeak': '押して話す',
    // 松开 结束
    'ReleaseEnd': '離して終了',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '音声認識の初期化に失敗しました。マイクの権限を確認してください',
    // 通过下方工具栏，输入文字或语音，即可进行翻译
    'EnterTextOrVoice': '下のツールバーを使用してテキストまたは音声を入力すると、翻訳できます',
    // 发送过于频繁，请稍后再试
    'SendTooFrequentlyPleaseTryAgainLater': '送信が頻繁すぎます。後でもう一度お試しください',
    // 连接失败，请稍后重试
    'ConnectionFailedPleaseTryAgainLater': '接続に失敗しました。後でもう一度お試しください',
  },
  ErrorPage: {
    // 该内容不支持当前语言
    '406': 'このコンテンツは現在の言語をサポートしていません',
    // 发生错误，请稍后再试
    '500': 'エラーが発生しました。後でもう一度お試しください',
  }
};