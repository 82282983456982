import {createStore} from 'vuex'
import {authApi} from '@/api';
import {tokenIsExp} from '@/utils/utils'

export default createStore({
    state: {
        token: localStorage.getItem("token") || "",
        merchant: null,
        modules: [],
        serviceLanguages: [],
        loadingShow: false,
        AzureSpeechToken: localStorage.getItem("AzureSpeechToken") || "",
        queryGroupId: localStorage.getItem("queryGroupId") || null,
    },
    getters: {
        token: state => state.token,
        merchant: state => state.merchant,
        isLogin: state => !!state.token,
        modules: state => state.modules,
        serviceLanguages: state => state.serviceLanguages,
        loadingShow: state => state.loadingShow,
        isFavorite: state => state.merchant?.base?.isFavorite || false,
        queryGroupId: state => state.queryGroupId,
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setMerchant(state, data) {
            state.merchant = data;
        },
        clearMerchant(state) {
            state.merchant = null;
        },
        setModules(state, data) {
            state.modules = data;
        },
        setServiceLanguages(state, data) {
            state.serviceLanguages = data;
        },
        setLoadingShow(state, data) {
            state.loadingShow = data;
        },
        setMerchantFavorite(state, data) {
            const base = state.merchant?.base;
            if (base) {
                state.merchant.base.isFavorite = data;
            }
        },
        checkAzureSpeechToken(state, data) {
            state.AzureSpeechToken = data;
        },
        setQueryGroupId(state, data) {
            state.queryGroupId = data;
        },
    },
    actions: {
        setToken({commit}, token) {
            localStorage.removeItem('token')
            localStorage.setItem("token", token);
            commit("setToken", token);
        },
        setMerchant({commit}, data) {
            commit("setMerchant", data);
        },
        clearMerchant({commit}) {
            commit("clearMerchant");
        },
        setModules({commit}, data) {
            commit("setModules", data);
        },
        setServiceLanguages({commit}, data) {
            commit("setServiceLanguages", data);
        },
        setLoadingShow({commit}, data) {
            commit("setLoadingShow", data);
        },
        resetData({commit}) {
            commit("setMerchant", null);
            commit("setModules", []);
            commit("setServiceLanguages", []);
        },
        setMerchantFavorite({commit}, data) {
            commit("setMerchantFavorite", data);
        },
        checkAzureSpeechToken({commit, state}) {
            const tokenPromise = new Promise(async (resolve, reject) => {
                const isExp = tokenIsExp(state.AzureSpeechToken)
                if (isExp) {
                    const res = await authApi.getUserAzureSpeech().catch(() => {
                        return {
                            data: {
                                token: ""
                            }
                        }
                    })
                    state.AzureSpeechToken = res.data.token
                    localStorage.setItem("AzureSpeechToken", res.data.token)
                }
                if (state.AzureSpeechToken) {
                    commit("checkAzureSpeechToken", state.AzureSpeechToken)
                    resolve(state.AzureSpeechToken)
                } else {
                    reject()
                }
            })
            return tokenPromise
        },
        setQueryGroupId({commit}, data) {
            localStorage.setItem("queryGroupId", data);
            commit("setQueryGroupId", data);
        },
    },
})
