export default function (api) {
  return {
    getMerchant(code) {
      let basePath = `merchant/${code}`
      return api.get(basePath)
    },
    // servicelanguages
    getServiceLanguages(code) {
      return api.get(`merchant/${code}/multilingual`)
    },
    // post /web/merchant/{{merchantId}}/message
    postMessage(id, data) {
      return api.post(`merchant/${id}/message`, data)
    },
    // get /web/merchant/{{merchantId}}/chatHistory
    getChatHistory(merchantCode, data) {
      return api.get(`/merchant/${merchantCode}/chatHistory`, {
        params: data,
      })
    },
    //
    getChatGroups(merchantCode, data) {
      return api.get(`/merchant/${merchantCode}/chatGroups`, {
        params: data,
      })
    },
    // get /web/user/azure
    getUserAzureSpeech(data) {
      return api.get(`speech/token`, {
        params: data,
      })
    },
    // get AI /merchant/1
    getQuery(data, setResponseText) {
      return api.get(`query`, {
        noLoading: true,
        params: data,
        onDownloadProgress: (progressEvent) => {
          const dataChunk = progressEvent.event.target.response
          setResponseText(dataChunk)
        }
      })
    },
    putQuery(id, data) {
      return api.put(`query/${id}`, data)
    },
    getCommonQuestion(data) {
      return api.get(`query/commonQuestion`, {
        params: data,
      })
    },
    anonymousLogin() {
      return api.post(`account/anonymousLogin`)
    },
  }
}
